import { graphql, navigate, PageProps } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { QueryFunction, useQuery } from 'react-query'
import { RepairSummaryQuery } from '../_generated/codegen/graphqlTypes'
import { RepairSummaryPageContext } from '../templates/RepairSummaryPage.context'
import { QuoteFormData } from '../models/QuoteFormData'
import fetchSaveApi from '../utils/fetchSaveApi'
import { QuoteResult } from '../models/QuoteResult'
import RepairSummaryBill from '../components/RepairSummaryBill'
import { PageContext } from '../../.gatsby/WrapPageElement'
import RepairSummaryOrder from '../components/RepairSummaryOrder'
import RepairSummaryPayment from '../components/RepairSummaryPayment'
import { WindowLocation } from '@reach/router'
import RepairSummaryPaymentSuccess from '../components/RepairSummaryPaymentSuccess'
import generateECommerceConfig from '../utils/generateECommerceConfig'
import { OrdersResourceApi } from '../_generated/openapi/ecommerce/apis/OrdersResourceApi'
import { WebUserElementApi } from '../_generated/openapi/ecommerce/apis/WebUserElementApi'
import { Category, SavePrice } from '../models/SavePrice'
import useSaveShopsQuery from '../hooks/useSaveShopsQuery'
import RepairSummaryAuth from '../components/RepairSummaryAuth'

export type OnAuthenticated = (email?: string) => Promise<Error | undefined>

export type QuoteParams = {
  pricingOffers: string[]
}

export type OnQuote = (quoteParams: QuoteParams) => void

const payboxResultParamKey = 'paybox_result'
const emailParamKey = 'email'
const interventionID = 'intervention_id'
const billingId = 'billing_id'

export type PayboxResultValue = 'success' | 'refused' | 'canceled' | 'waiting'

const payboxResultValues: PayboxResultValue[] = [
  'success',
  'refused',
  'canceled',
  'waiting',
]

type SummaryParams = {
  [payboxResultParamKey]: PayboxResultValue
  [emailParamKey]: string
  [interventionID]: string
  [billingId]: string
}

const paramKeys: (keyof SummaryParams)[] = [
  'paybox_result',
  'email',
  'intervention_id',
  'billing_id',
]

const getParams = (
  location: WindowLocation<unknown>
): SummaryParams | undefined => {
  const params = new URLSearchParams(location.search)

  if (paramKeys.filter((key) => params.has(key)).length !== paramKeys.length) {
    return undefined
  }

  const payboxResult = params.get(payboxResultParamKey)
  if (
    !payboxResult ||
    !payboxResultValues.includes(payboxResult as PayboxResultValue)
  ) {
    return undefined
  }

  return Array.from(params.entries())
    .filter(([key]) => paramKeys.includes(key as any))
    .reduce(
      (accu, [key, value]) => ({ ...accu, [key]: value }),
      {}
    ) as SummaryParams
}

type PriceQueryProps = {
  brandName: string
  modelName: string
  shopId: string
  repairCode: string
}

const priceQuery: QueryFunction<SavePrice, [string, PriceQueryProps]> = async ({
  queryKey,
}) => {
  const [, { brandName, modelName, shopId, repairCode }] = queryKey
  let data: SavePrice | undefined

  data = await fetchSaveApi<SavePrice>(
    `/website/v1/prices/SPAREPART/${brandName}/${modelName}/${repairCode}/${shopId}`
  )

  if (
    !data ||
    (Object.keys(data).length === 0 && data.constructor === Object) ||
    data.categories.length === 0 ||
    data.categories.filter((categories) => categories.pricings.length !== 0)
      .length === 0
  ) {
    sessionStorage.removeItem('shopIdStored')
    throw new Error('Prix introuvable')
  }

  return data
}

const RepairSummaryPage: React.FC<
  PageProps<RepairSummaryQuery, PageContext & RepairSummaryPageContext>
> = ({ data: pageData, pageContext, location }) => {
  //get url param code + shopId
  const segments = location.pathname.split('/')

  segments.pop()
  const shopId = segments.pop() ?? ''

  const codeString = segments.pop()
  const code = codeString?.split('+') ?? [pageContext.repairCode]

  if (code.length > 1) {
    pageContext.repairCode = codeString!
  }
  const shops = useSaveShopsQuery()
  const shop = shops.find((shop) => {
    return shop.shopId?.toLowerCase() === (shopId ?? '').toLowerCase()
  })

  // store shopID in local storage
  useEffect(() => {
    sessionStorage.setItem('shopIdStored', shopId)
  }, [])

  const { data: priceData, error: priceError } = useQuery<
    SavePrice | undefined,
    Error,
    SavePrice | undefined,
    [string, PriceQueryProps]
  >(['price', { ...pageContext, shopId }], priceQuery, {
    retry: false,
    staleTime: Infinity,
  })

  const data: IRepairSummary = useMemo(
    () => ({ ...pageData, price: priceData ?? priceError ?? undefined }),
    [pageData, priceData, priceError]
  )

  const params = useMemo(() => getParams(location), [location])
  const [selectedSparePart, setSelectedSparePart] = useState(
    new Map<string, number>()
  )

  const [categories, setCategories] = useState<Category[]>([])
  useEffect(() => {
    if (data.price && !(data.price instanceof Error)) {
      //sort desc
      setCategories(
        data.price.categories.sort(
          (a, b) => b.pricings[0].priceTTC - a.pricings[0].priceTTC
        )
      )
    }
  }, [data.price])

  useEffect(() => {
    //initialisation de la selection des pieces ori/compatible
    setSelectedSparePart(() => {
      let selectedSparePartMap = new Map<string, number>()

      categories.map((category) => {
        selectedSparePartMap.set(category.category.toUpperCase(), 0)
      })
      return selectedSparePartMap
    })
  }, [categories])

  const [step, setStep] = useState<
    | { type: 'summary'; quoteParams?: QuoteParams }
    | { type: 'auth'; quoteType: 'quote' | 'send'; quoteParams: QuoteParams }
    | { type: 'quoteSuccess'; quoteReference: string; quoteParams: QuoteParams }
    | {
        type: 'payment'
        quote: QuoteResult
        email: string
        quoteParams: QuoteParams
      }
    | { type: 'payboxResult'; value: 'success' }
  >(
    params?.paybox_result === 'success'
      ? { type: 'payboxResult', value: params.paybox_result }
      : { type: 'summary' }
  )

  const handlePayboxResult = useCallback(
    async (params: SummaryParams) => {
      if (params.paybox_result === 'success') {
        setStep({ type: 'payboxResult', value: params.paybox_result })

        try {
          // ecommerce config
          const config = await generateECommerceConfig()

          // FIXME insert result if succeeded
          const webUserElementApi = new WebUserElementApi(config)
          await webUserElementApi.insertintotrepidaiUsingPOST({
            webUser: {
              login: params.email,
            },
          })

          // FIXME update order
          const ordersResourceApi = new OrdersResourceApi(config)
          await ordersResourceApi.updateOrdersUsingPUT({
            orders: {
              orderNumber: '',
              interventionID: parseInt(params.intervention_id),
              billingId: parseInt(params.billing_id),
              shopId: shop?.shopId ?? '',
              customer: {
                email: params.email,
              },
            },
          })
        } catch (e) {
          console.error(e)
        }
      }
    },
    [params]
  )

  useEffect(() => {
    if (!params || !params.paybox_result) {
      return
    }
    navigate(location.pathname)
    handlePayboxResult(params)
  }, [params, location, handlePayboxResult])

  const layoutProps = pageContext.layoutProps ?? {}

  const handleQuote = useCallback(
    async (
      type: 'quote' | 'send',
      quoteParams: QuoteParams,
      categories: Category[],
      selectedSparePart: Map<string, number>,
      email?: string
    ): Promise<Error | undefined> => {
      if (!email) {
        return new Error(`Un email est requis`)
      }

      // send quote
      // this is the data sent
      const formData: QuoteFormData = {
        hgHomeProcess: type === 'send',
        webSiteOrigin: 'SAVE_CO',
        magasinid: shop?.shopId ?? '',
        shopId: shop?.shopId ?? '',
        brand: pageContext.brandName,
        modele: pageContext.modelName,
        devisDetailAsList: [
          ...quoteParams.pricingOffers.map((reference) => ({
            accessoryReference: reference,
          })),
          ...Array.from(selectedSparePart.values()).map((selectedIdx, idx) => ({
            sparePartReference: categories[idx].pricings[selectedIdx].reference,
          })),
        ],
        email,
      }
      const quoteResult = await fetchSaveApi<QuoteResult>('/website/v1/quote', {
        method: 'POST',
        body: JSON.stringify(formData),
      })
      if (!quoteResult) {
        return new Error("Une erreur est survenue lors de l'envoi du devis")
      }

      // FIXME create order only if type send
      // if (type === 'send') {
      //   try {
      //     const config = await generateECommerceConfig()
      //     const ordersResourceApi = new OrdersResourceApi(config)
      //     await ordersResourceApi.createOrdersUsingPOST({
      //       orders: {
      //         orderNumber: '',
      //         interventionID: quoteResult.intervention_id,
      //         billingId: quoteResult.billDetails[0].billingId,
      //         shopId: pageContext.shopId,
      //         customer: {
      //           email,
      //         },
      //       },
      //     })
      //   } catch (e) {
      //     console.error(e)
      //     return new Error(
      //       'Une erreur est survenue lors de la création de la commande'
      //     )
      //   }
      // }

      const { reference } = quoteResult
      if (type === 'quote') {
        setStep({
          type: 'quoteSuccess',
          quoteReference: reference,
          quoteParams,
        })
      } else {
        setStep({ type: 'payment', quote: quoteResult, email, quoteParams })
      }
      return undefined
    },
    [pageContext]
  )

  const handleBillAction = useCallback(
    (type: 'quote' | 'send') => (quoteParams: QuoteParams) => {
      // TODO: if already connected call directly handleQuote
      // if (user) {
      //   handleQuote(type, quoteParams, user.email)
      //   return
      // }
      setStep({ type: 'auth', quoteType: type, quoteParams })
    },
    [handleQuote]
  )

  const handleAuthenticated = useCallback(
    async (email?: string) => {
      if (step.type !== 'auth') {
        return new Error('L’étape doit être égale à `auth`')
      }

      return handleQuote(
        step.quoteType,
        step.quoteParams,
        categories,
        selectedSparePart,
        email
      )
    },
    [step, handleQuote]
  )

  const handleBack = useCallback(
    () =>
      setStep({
        type: 'summary',
        quoteParams: 'quoteParams' in step ? step.quoteParams : undefined,
      }),
    [step]
  )

  useEffect(() => scrollTo(0, 0), [step])

  if (step.type === 'summary') {
    return (
      <RepairSummaryBill
        pageContext={pageContext}
        layoutProps={layoutProps}
        data={data}
        code={code!}
        quoteParams={step.quoteParams}
        onQuote={handleBillAction('quote')}
        onSend={handleBillAction('send')}
        selectedSparePart={selectedSparePart}
        setSelectedSparePart={setSelectedSparePart}
        categories={categories}
        shop={shop}
      />
    )
  }

  if (step.type === 'auth') {
    return (
      <RepairSummaryAuth
        onBack={handleBack}
        type={step.quoteType}
        onAuthenticated={handleAuthenticated}
      />
    )
  }

  if (step.type === 'quoteSuccess') {
    return (
      <RepairSummaryOrder
        data={data}
        onBack={handleBack}
        quoteParams={step.quoteParams}
        layoutProps={layoutProps}
        quoteReference={step.quoteReference}
      />
    )
  }

  if (step.type === 'payment') {
    return (
      <RepairSummaryPayment
        onBack={handleBack}
        layoutProps={layoutProps}
        quote={step.quote}
        email={step.email}
      />
    )
  }

  if (step.type === 'payboxResult' && step.value === 'success') {
    return <RepairSummaryPaymentSuccess />
  }

  return <></>
}

export type IRepairSummary = RepairSummaryQuery & {
  price?: SavePrice | Error
}

export const query = graphql`
  query RepairSummary($brandName: String!, $modelName: String!) {
    model: saveModel(brandName: { eq: $brandName }, name: { eq: $modelName }) {
      brandName
      name
      imgUrl
      imageFile {
        publicURL
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED, width: 110)
        }
        name
      }
    }
  }
`

export default RepairSummaryPage
