import getRepairLabel from './getRepairLabel'

// FIXME: to delete when label will be cleanup upstream
const fixRepairShortLabel = (code: string): string => {
  const label = getRepairLabel(code)
  if (label) {
    const shortLabel = label
      .replace('Remplacement ', '')
      .replace('Réparation ', '')
    return shortLabel.replace(/^./, shortLabel[0].toUpperCase())
  }

  return ''
}

export default fixRepairShortLabel
