import styled from 'styled-components'

const PaymentMadeContainer = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 22px;
  padding-bottom: 22px;

  & > *[class^='PaymentMadeCard'] {
    max-width: min(506px, calc(100% - 2 * 22px));
    margin: 0 auto;
    width: 100%;
  }
`

export default PaymentMadeContainer
