import React, { useCallback, useMemo, useState } from 'react'
import RepairContent from './RepairContent'
import { TimeTable } from './RepairShopMapList'
import RepairSummary from './RepairSummary'
import {
  RepairSummaryPageContext,
  repairSummaryPageStepLabel,
} from '../templates/RepairSummaryPage.context'
import {
  IRepairSummary,
  OnQuote,
  QuoteParams,
} from '../templates/RepairSummaryPage'
import { LayoutProps } from './Layout.context'
import { useLayoutContext } from './Layout'
import { useModal } from './ReactModal'
import RecoverModal from './RecoverModal'
import { PRICING_OFFER_RECOVER_REFERENCE } from '../data/pricingOfferRecoverReference'
import { Helmet } from 'react-helmet'
import { Category } from '../models/SavePrice'
import RepairError from './RepairError'
import fixRepairLabel from '../utils/fixRepairLabel'
import { SaveShopsQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'
import MissingReparationPopup from './MissingReparationPopup'
import { useEffect } from 'react'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

interface RepairSummaryBillProps {
  pageContext: RepairSummaryPageContext
  layoutProps: LayoutProps
  data: IRepairSummary
  code: string[]
  quoteParams?: QuoteParams
  onQuote: OnQuote
  onSend: OnQuote
  selectedSparePart: Map<string, number>
  setSelectedSparePart: React.Dispatch<
    React.SetStateAction<Map<string, number>>
  >
  categories: Category[]
  shop:
    | NonNullable<Unpacked<SaveShopsQuery['allSaveShop']['nodes']>>
    | undefined
}

const RepairSummaryBill: React.FC<RepairSummaryBillProps> = ({
  pageContext,
  layoutProps,
  code,
  data,
  onQuote,
  onSend,
  quoteParams,
  selectedSparePart,
  setSelectedSparePart,
  categories,
  shop,
}) => {
  useLayoutContext(layoutProps)
  const { isOpen, onRequestClose, requestOpen } = useModal()
  //price in a single list
  const priceList = categories
    .map((category) => category.pricings)
    .reduce((previous, current) => previous.concat(current), [])

  const mainPrice =
    priceList!.reduce(
      (previous, current) =>
        current.categoryType === 'ORIGINAL'
          ? previous + current.priceTTC
          : previous,
      0
    ) ?? undefined

  const webPromo = useMemo(
    () =>
      categories && categories.length > 0
        ? Array.from(selectedSparePart.values())
            .map((selectedIdx, idx) =>
              categories[idx].pricings[selectedIdx]?.discountTTC
                ? -categories[idx].pricings[selectedIdx]?.discountTTC!
                : 0
            )
            .reduce((previous, current) => previous + current, 0)
        : 0,
    [data.price, priceList]
  )

  const [pricingOffers, setPricingOffers] = useState<string[]>(
    quoteParams?.pricingOffers ?? []
  )

  const handleRecover = useCallback(
    (reference: string) => () => {
      if (reference === PRICING_OFFER_RECOVER_REFERENCE) {
        requestOpen()
      }
    },
    [pricingOffers]
  )

  const handleCheckBox = useCallback(
    (reference: string) => (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (pricingOffers.includes(reference)) {
        setPricingOffers((old) => old.filter((x) => x !== reference))
      } else {
        setPricingOffers((old) => [...old, reference])
      }
    },
    [pricingOffers]
  )

  const handlePricingOfferAccept = useCallback(
    (reference: string) => () => {
      setPricingOffers((old) => [...old, reference])
      onRequestClose()
    },
    []
  )

  const additionnalTransactions = useMemo(
    () =>
      pricingOffers.includes('COVER_FACE') &&
      !(data.price instanceof Error) &&
      data.price?.webPricingOffer?.promoAmount
        ? [data.price?.webPricingOffer?.promoAmount]
        : [],
    [pricingOffers]
  )

  const handleQuote = useCallback(
    (type: 'quote' | 'send') => () => {
      if (type === 'quote') {
        onQuote({ pricingOffers })
      } else {
        onSend({ pricingOffers })
      }
    },
    [onQuote, onSend, pricingOffers]
  )
  // affichage popup s'il manque une réparation (multirepar)
  const [popupOpen, setPopupOpen] = useState(false)

  const missingReparation = code.filter(
    (code) =>
      !!!categories
        .map((categories) => categories.category.toLowerCase())
        .includes(code)
  )

  useEffect(() => {
    if (
      missingReparation.length > 0 &&
      categories.length > 0 &&
      code.length > 1
    ) {
      sessionStorage.removeItem('shopIdStored')

      setPopupOpen(true)
    }
  }, [missingReparation.length])

  if (data.price instanceof Error) {
    return (
      <RepairContent>
        <RepairContent.Card index={5} title={'Erreur'}>
          <RepairError />
        </RepairContent.Card>
      </RepairContent>
    )
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`Votre ${capitalizeFirstLetter(pageContext.brandName)} ${
            pageContext.modelName
          } n’a plus de batterie ? Nos experts remplacent la batterie de votre téléphone en moins de 40 minutes`}
        />
      </Helmet>

      <RepairContent>
        <RepairContent.Card
          index={5}
          title={
            <RepairContent.SeoHeader
              title={`${
                pageContext.repairCode.includes('+')
                  ? 'Réparations multiples'
                  : fixRepairLabel(pageContext.repairCode ?? '')
              } de mon téléphone ${capitalizeFirstLetter(
                pageContext.brandName
              )} ${pageContext.modelName} au ${shop?.shopName ?? ''}`}
              subtitle={repairSummaryPageStepLabel}
            />
          }
        >
          <RepairSummary
            left={
              <>
                <RepairSummary.Card
                  avatar={<RepairSummary.PhoneAvatar item={data.model ?? {}} />}
                  title={`${capitalizeFirstLetter(data.model?.brandName)} / ${
                    data.model?.name
                  }`}
                >
                  <RepairSummary.Blockquote
                    label={
                      'Chez Save, on sauve votre téléphone et la planète en même temps !'
                    }
                  />
                </RepairSummary.Card>

                <RepairSummary.Card
                  avatar={<RepairSummary.ShopAvatar />}
                  title={shop?.shopName}
                >
                  <RepairSummary.Address>
                    {shop?.shopFullAddress}
                  </RepairSummary.Address>
                  <TimeTable item={shop ?? {}} />
                </RepairSummary.Card>
              </>
            }
            right={
              <RepairSummary.Summary>
                <RepairSummary.Bill
                  categories={categories}
                  webPromo={webPromo}
                  additionnalTransactions={additionnalTransactions}
                  storeName={shop?.shopName ?? ''}
                  selected={selectedSparePart}
                  setSelected={setSelectedSparePart}
                >
                  {data.price?.webPricingOffer?.promoActivated &&
                    data.price.webPricingOffer?.promoReference && (
                      <RepairSummary.Card
                        avatar={<RepairSummary.RecoverAvatar />}
                        active={pricingOffers.includes(
                          data.price.webPricingOffer?.promoReference
                        )}
                        onClick={handleRecover(
                          data.price.webPricingOffer?.promoReference
                        )}
                        onCheckBoxClick={handleCheckBox(
                          data.price.webPricingOffer?.promoReference
                        )}
                      >
                        <RepairSummary.Repair
                          label={
                            // <span
                            //   dangerouslySetInnerHTML={{
                            //     __html: `
                            //       ${data.price.webPricingOffer?.promoTitle}
                            //       ${data.price.webPricingOffer?.promoDescription}
                            //     `,
                            //   }}
                            // />
                            [
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `
                                  Profitez de notre protection
                                  <br />
                                  sur-mesure reCover, anti-choc
                                `,
                                }}
                              />,
                            ]
                          }
                          value={data.price.webPricingOffer?.promoAmount ?? 0}
                        />
                      </RepairSummary.Card>
                    )}
                </RepairSummary.Bill>

                <RepairSummary.Buttons>
                  <RepairSummary.Button
                    disabled={mainPrice === undefined}
                    onClick={handleQuote('quote')}
                  >
                    Obtenir mon devis
                  </RepairSummary.Button>
                  {/* <RepairSummary.ButtonWithDescription
                    button={
                      <RepairSummary.Button
                        color="secondary"
                        disabled={data.price?.priceTTC === undefined}
                        onClick={handleQuote('send')}
                      >
                        J’envoie mon smartphone
                      </RepairSummary.Button>
                    }
                    description={
                      <>
                        Vous serez alors redirigé vers une page afin de payer le
                        montant du transport aller (8,40€). Votre magasin
                        prendra en charge le retour.
                      </>
                    }
                  /> */}
                </RepairSummary.Buttons>
              </RepairSummary.Summary>
            }
          />
        </RepairContent.Card>

        {popupOpen ? (
          <MissingReparationPopup
            reparationMissing={missingReparation}
            closePopup={() => setPopupOpen(false)}
          />
        ) : null}
      </RepairContent>

      <RecoverModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onAccept={handlePricingOfferAccept(
          data.price?.webPricingOffer?.promoReference ?? ''
        )}
      />
    </>
  )
}

export default RepairSummaryBill
