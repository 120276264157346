import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Category, Pricing } from '../models/SavePrice'

import fixRepairShortLabel from '../utils/fixRepairShortLabel'
import formatPrice from '../utils/formatPrice'
import LoadingIcon from './LoadingIcon'

const RepairSummaryContainer = styled.div`
  padding: 0 3px 16px 3px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 23px 18px 23px;
    flex-direction: row;
    gap: 17px;

    & > * {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & > :not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.muted};
      padding-right: 17px;
    }
  }

  & > div {
    flex: 1;
  }
`

interface PriceContainerProps {
  strong?: 'true'
}

const PriceContainer = styled.span<PriceContainerProps>`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;

  color: ${({ theme }) => theme.colors.body};

  ${({ strong }) => {
    return (
      strong === 'true' &&
      css`
        font-weight: bold;
      `
    )
  }}
`

interface PriceProps {
  value: number
  strong?: boolean
}

const Price: React.FC<PriceProps> = ({ value, strong }) => (
  <PriceContainer strong={strong ? 'true' : undefined}>
    {formatPrice(value)}
  </PriceContainer>
)

const ChoicesContainer = styled.div`
  &:has(input) {
    padding-left: 5px;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-left: 20px;
    }
  }
`

const BillItemSelectorContainer = styled.div`
  padding: 10px 0;
  width: 100%;
`

const SelectedItemLabel = styled.label`
  padding: 0;
  display: flex;
  text-align: start;
  margin-top: 3px;
`

const ChoiceText = styled.div<{ uniqueItem: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.uniqueItem ? `row-reverse` : `row`)};
  width: 155px;
  align-items: center;
  font-weight: bold;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 177px;
  }
  justify-content: space-between;
  & > div {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }
`

const PriceSpan = styled.span`
  min-width: 55px;
  text-align: right;
`

const PriceStrikedSpan = styled.span`
  color: #4e4e4e;
  font-size: 10px;
  padding: 0px 5px;

  font-weight: normal;
`

const DiscountDiv = styled.div`
  border-radius: 25px;
  background: #0f4c81;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  font-size: 8px;
  min-width: 30px;
  max-height: 12px;
  justify-content: center;
`

const RowContainer = styled.div<{ isChecked: boolean }>`
  display: flex;
  opacity: ${(props) => (props.isChecked ? '1' : '0.7')};
  justify-content: flex-end;
`
const Strike = styled.s`
  fontweight: normal;
  min-width: 56px;
  text-align: right;
`

const RowDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

interface ChoicesPricesProps {
  choicesPrices: Pricing[]
  selected: Map<string, number>
  setSelected: React.Dispatch<React.SetStateAction<Map<string, number>>>
  storeName: string
}

const ChoicesPrice: React.FC<ChoicesPricesProps> = ({
  choicesPrices,
  selected,
  setSelected,
  storeName,
}) => {
  return (
    <ChoicesContainer>
      {choicesPrices.length > 1 && (
        <SparepartToggleButton
          selected={selected}
          categoryIdx={choicesPrices[0].category}
          setSelected={setSelected}
          storeName={storeName}
        />
      )}
      <RowDiv>
        {choicesPrices.map((choice, selectedIdx) => {
          return (
            <RowContainer
              key={selectedIdx}
              isChecked={selected.get(choice.category) == selectedIdx}
            >
              {choice.discountTTC &&
              selected.get(choice.category) == selectedIdx ? (
                <ChoiceText uniqueItem={false}>
                  <Strike>
                    <PriceStrikedSpan>
                      {formatPrice(choice.priceTTC)}
                    </PriceStrikedSpan>
                  </Strike>

                  <DiscountDiv>{choice.discountType}</DiscountDiv>
                  <PriceSpan>
                    {formatPrice(choice.priceTTC - (choice.discountTTC ?? 0))}
                  </PriceSpan>
                </ChoiceText>
              ) : (
                <ChoiceText uniqueItem>
                  {formatPrice(choice.priceTTC - (choice.discountTTC ?? 0))}
                </ChoiceText>
              )}
            </RowContainer>
          )
        })}
      </RowDiv>
    </ChoicesContainer>
  )
}

const SwitchButton = styled.div`
  // border: 1px solid #c2c2c2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  width: 100px;
  height: 55px;
  text-align: center;
  font-size: 10px;
  color: #0f4c81;
  position: relative;
  padding-bottom: 25px;
  position: relative;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.md}) and (min-width: ${({ theme }) =>
      theme.breakpoints.sm}) {
    width: 369px;
  }
`

const SwitchSpanText = styled.span`
  position: absolute;
  bottom: 2px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  user-select: all;
  width: -webkit-fill-available;
  margin: 0px 5px;
`

const CompatibleSpan = styled.span`
  word-break: break-word;
  display: block;
  line-height: 12px;
`

const SwitchButtonCheckbox = styled.input`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;

  &:checked + .switch-button-label:before {
    transform: translateY(26px);
    transition: transform 300ms linear;
  }

  :not(&:checked) + .switch-button-label {
    color: #ffffff;
  }

  &:checked ~ .switch-span-text {
    color: #ffffff;
  }

  & + .switch-button-label {
    position: relative;
    display: block;
    user-select: none;
    pointer-events: auto;
    color: #0f4c81;
    height: 16px;

    &:before {
      content: '';
      background: #0f4c81;
      height: 29px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 5px;
      transform: translateY(0);
      transition: transform 300ms;
    }

    .switch-button-label-span {
      position: relative;
      top: 4px;
    }
  }
`
const SwitchButtonLabel = styled.label`
  & > span:last-child {
    display: flex;
    justify-content: center;
  }
`

const ChoiceImageWithTooltip = styled.div<{ isLeft: boolean }>`
  position: relative;
  z-index: 10;
  pointer-events: all;

  & > span {
    position: absolute;
    bottom: 120%;
    right: ${(props) => (props.isLeft ? `none` : `50%`)};
    color: white;
    background-color: grey;
    border-radius: 4px;
    display: none;
    padding: 5px;
    text-align: justify;
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      right: none;
      left: -13px;
    }
  }
  &:hover > span {
    display: flex;
    width: 200px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 225px;
    }
  }
`

const TooltipImg = styled.img`
  margin-bottom: 3px;
`

interface SparepartToggleProps {
  selected: Map<string, number>
  categoryIdx: string
  setSelected: React.Dispatch<React.SetStateAction<Map<string, number>>>
  storeName: string
}
const SparepartToggleButton: React.FC<SparepartToggleProps> = ({
  selected,
  categoryIdx,
  setSelected,
  storeName,
}) => {
  //gestion de la selection des pieces compatibles/origines
  const handleSelectionSparePart = useCallback(
    (isChecked: boolean, category: string) => {
      setSelected((previousSelect) => {
        let newSelected = new Map(previousSelect)

        newSelected.set(category, isChecked ? 1 : 0)

        return newSelected
      })
    },
    []
  )

  const tooltipText = (isOriginal: boolean) => {
    return isOriginal
      ? `Votre magasin ${storeName} est intégré au programme de réparation indépendant Apple (IRP). Formé et outillé selon les standards d’Apple, la réparation sera réalisée avec une pièce d’origine Apple qui vous garantit la meilleure qualité possible.`
      : `Votre magasin ${storeName} utilise des pièces détachées de qualité et offre une garantie nationale d’un an sur la réparation (pièce & main d’oeuvre). Dans le cas où votre appareil est toujours sous garantie constructeur (2 ans), nous vous recommandons de vous orienter vers une pièce d’origine.`
  }
  return (
    <SwitchButton>
      <SwitchButtonCheckbox
        className="switch-button-checkbox"
        type="checkbox"
        id="SwitchButtonCheckbox"
        key={1}
        name={categoryIdx}
        value={1}
        checked={selected.get(categoryIdx) === 1}
        onChange={(e) =>
          handleSelectionSparePart(e.target.checked, categoryIdx)
        }
      />
      <SwitchButtonLabel className="switch-button-label">
        <span className="switch-button-label-span">
          Origine
          <ChoiceImageWithTooltip isLeft={true}>
            <TooltipImg src="/images/RepairIndicator.svg" width={7} />
            <span>{tooltipText(true)}</span>
          </ChoiceImageWithTooltip>
        </span>
      </SwitchButtonLabel>

      <SwitchSpanText className="switch-span-text">
        <CompatibleSpan>Compatible</CompatibleSpan>
        <ChoiceImageWithTooltip isLeft={false}>
          <TooltipImg src="/images/RepairIndicator.svg" width={7} />
          <span>{tooltipText(false)}</span>
        </ChoiceImageWithTooltip>
      </SwitchSpanText>
    </SwitchButton>
  )
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 13px;
  }
`

interface RepairSummaryProps {
  left: React.ReactNode
  right: React.ReactNode
}

const RepairSummary: React.FC<RepairSummaryProps> = ({ left, right }) => (
  <RepairSummaryContainer>
    <Column>{left}</Column>
    <Column>{right}</Column>
  </RepairSummaryContainer>
)

const CardContainer = styled.div`
  padding: 7px 20px 7px 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  margin-right: 3px;

  ${({ onClick }) => {
    return (
      onClick &&
      css`
        cursor: pointer;
      `
    )
  }}

  & > *[class^='RepairSummary__CardCheckBoxContainer'] {
    align-self: center;
    margin-left: 4px;
    margin-right: -8px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-right: -18px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 10px 20px 10px 0;
  }

  & > :last-child {
    flex: 1;
  }
`

const CardAvatar = styled.span`
  width: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 93px;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
  gap: 2px;

  & > *[class^='RepairShopMapList__Timetable'] {
    align-self: flex-start;
  }
`

const Title = styled.strong`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 14px;
    line-height: 16px;
  }
`

const CardCheckBoxContainer = styled.span`
  width: 34px;
  height: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;

  & > div {
    transform: translateY(4px);
  }
`

const BillCheckBoxOnDiv = styled.div<{ active?: boolean }>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
`

const BillCheckBoxOffDiv = styled.div<{ active?: boolean }>`
  display: ${(props) => (props.active ? 'none' : 'flex')};
`

interface CardCheckBoxProps {
  active?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const CardCheckBox: React.FC<CardCheckBoxProps> = ({ active, onClick }) => (
  <CardCheckBoxContainer onClick={onClick}>
    <BillCheckBoxOnDiv active={active}>
      <img src="/images/BillCheckBoxOn.svg" alt="on" placeholder="blurred" />
    </BillCheckBoxOnDiv>
    <BillCheckBoxOffDiv active={active}>
      <img src="/images/BillCheckBoxOff.svg" alt="off" placeholder="blurred" />
    </BillCheckBoxOffDiv>
  </CardCheckBoxContainer>
)

interface CardProps {
  avatar: React.ReactNode
  title?: React.ReactNode
  active?: boolean
  onClick?: () => void
  onCheckBoxClick?: (e: React.MouseEvent) => void
  children: React.ReactNode
}

const Card: React.FC<CardProps> = ({
  avatar,
  title,
  children,
  active,
  onClick,
  onCheckBoxClick,
}) => (
  <CardContainer onClick={onClick}>
    {onCheckBoxClick && (
      <CardCheckBox active={active} onClick={onCheckBoxClick} />
    )}
    <CardAvatar>{avatar}</CardAvatar>
    <CardContent>
      {title && <Title>{title}</Title>}
      {children}
    </CardContent>
  </CardContainer>
)

const AvatarContainer = styled.span`
  display: inline-block;
  border-radius: 100px;
  overflow: hidden;
  width: 37px;
  height: 37px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 47px;
    height: 47px;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`

const ShopAvatar: React.FC = () => (
  <AvatarContainer>
    <StaticImage
      src="../images/ShopAvatar.png"
      alt={''}
      quality={100}
      placeholder="blurred"
      width={47}
      height={47}
    />
  </AvatarContainer>
)

const RecoverAvatar: React.FC = () => (
  <AvatarContainer>
    <StaticImage
      src="../../static/images/services_save/recover.jpg"
      alt={'reCover'}
      quality={100}
      placeholder="blurred"
      width={47}
      height={47}
    />
  </AvatarContainer>
)

interface DynamicAvatarProps {
  image: IGatsbyImageData
  alt: string
}

const DynamicAvatar: React.FC<DynamicAvatarProps> = ({ image, alt }) => (
  <AvatarContainer>
    <GatsbyImage image={image} alt={alt} />
  </AvatarContainer>
)

const PhotoAvatarContainer = styled.span`
  display: inline-block;
  width: 44px;
  height: 44px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 93px;
    height: 101px;
  }

  & > * {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain !important;
  }
`

interface PhoneAvatarProps {
  item: {
    name?: string | null
    imageFile?: {
      publicURL?: string | null
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData | null
      } | null
    } | null
  }
}

const PhoneAvatar: React.FC<PhoneAvatarProps> = ({ item }) => {
  const image = useMemo(() => {
    const imageData = item.imageFile?.childImageSharp?.gatsbyImageData
    if (imageData) {
      return getImage(imageData)
    }
    return undefined
  }, [])

  return (
    <PhotoAvatarContainer>
      {image ? (
        <GatsbyImage image={image} alt={item.name ?? ''} />
      ) : item.imageFile?.publicURL ? (
        <img src={item.imageFile?.publicURL ?? ''} />
      ) : (
        <StaticImage
          src="../images/SmartphoneDefault.png"
          alt={item.name ?? ''}
          quality={100}
          placeholder="blurred"
          width={101}
          height={101}
        />
      )}
    </PhotoAvatarContainer>
  )
}

const Summary = styled.div`
  padding: 0 2px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
    gap: 27px;
  }
`

interface ButtonProps {
  color?: 'secondary'
}

const Button = styled.button<ButtonProps>`
  border-radius: 39px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 300ms ease;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.primary};

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ color }) => {
    return (
      color === 'secondary' &&
      css`
        background-color: ${({ theme }) => theme.colors.secondary};

        &:hover {
          background-color: ${({ theme }) => theme.colors.primary};
        }
      `
    )
  }}
`

const BillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`

interface BillItemContainerProps {
  strong?: 'true'
  flexRow: boolean
}

function getGridTemplate(haveDiscount: boolean, haveSparepartChoice: boolean) {
  if (haveDiscount && haveSparepartChoice) {
    return `48px minmax(0, 6fr)`
  } else if (haveSparepartChoice) {
    return `minmax(0, 1fr) minmax(0, 4fr)`
  } else {
    return `minmax(0, 2fr) minmax(0, 2fr)`
  }
}

function getJustifyContent(
  haveDiscount: boolean,
  haveSparepartChoice: boolean
) {
  if (haveDiscount && haveSparepartChoice) {
    return `space-between`
  } else if (haveSparepartChoice) {
    return `space-between`
  } else {
    return `flex-end`
  }
}

const LabelRadioButtonWrapper = styled.div<{
  haveDiscount: boolean
  haveSparepartChoice: boolean
}>`
  display: grid;

  grid-template-columns: ${(props) =>
    getGridTemplate(props.haveDiscount, props.haveSparepartChoice)};

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
      getJustifyContent(props.haveDiscount, props.haveSparepartChoice)};
  }
`

const BillItemContainer = styled.li<BillItemContainerProps>`
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px 6px 6px;
  flex-direction: ${(props) => (props.flexRow ? `row` : `column`)};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 12px;
    line-height: 16px;
    padding: 10px 14px;
  }

  ${({ strong }) => {
    return (
      strong === 'true' &&
      css`
        font-weight: bold;
      `
    )
  }}
`

const BillItemLine = styled.hr`
  border-bottom: none;
  border-right: none;
  margin: 15px 0px;
`

interface BillItemProps {
  label: string
  value?: number
  strong?: boolean
  webPromo?: number
}

const BillItem: React.FC<BillItemProps> = ({
  label,
  value,
  strong,
  webPromo,
}) => (
  <BillItemContainer strong={strong ? 'true' : undefined} flexRow={true}>
    <strong>{label}</strong>
    {value === undefined ? (
      <LoadingIcon />
    ) : (
      <div>
        {webPromo != 0 && (
          <PriceStrikedSpan>
            <s>{formatPrice(value - (webPromo ?? 0))} </s>
          </PriceStrikedSpan>
        )}
        <Price strong={strong} value={value} />
      </div>
    )}
  </BillItemContainer>
)

// TODO : ici remplace price par le component Choices et lui transmettre price
interface BillItemChoiceProps {
  choices: Category[]
  strong?: boolean
  selected: Map<string, number>
  setSelected: React.Dispatch<React.SetStateAction<Map<string, number>>>
  storeName: string
}

const BillItemChoice: React.FC<BillItemChoiceProps> = ({
  choices,
  strong,
  selected,
  setSelected,
  storeName,
}) => {
  return (
    <BillItemContainer strong={strong ? 'true' : undefined} flexRow={false}>
      {choices === undefined ? (
        <LoadingIcon />
      ) : (
        <BillItemSelectorContainer>
          {/* //radio button par type de réparation */}
          {choices.map((category, selectedIdx) => (
            <div key={selectedIdx}>
              <LabelRadioButtonWrapper
                key={selectedIdx}
                haveDiscount={
                  category.pricings.filter((cat) => cat.discountType !== null)
                    .length > 0
                }
                haveSparepartChoice={category.pricings.length > 1}
              >
                <SelectedItemLabel>
                  {fixRepairShortLabel(category.category.toUpperCase())}
                </SelectedItemLabel>
                <ChoicesPrice
                  choicesPrices={category.pricings}
                  selected={selected}
                  setSelected={setSelected}
                  storeName={storeName}
                />
              </LabelRadioButtonWrapper>
              {choices.length > 1 && selectedIdx != choices.length - 1 && (
                <BillItemLine />
              )}
            </div>
          ))}
        </BillItemSelectorContainer>
      )}
    </BillItemContainer>
  )
}

interface BillProps {
  categories: Category[]
  webPromo?: number
  additionnalTransactions?: number[]
  storeName: string
  selected: Map<string, number>
  setSelected: React.Dispatch<React.SetStateAction<Map<string, number>>>
  children: React.ReactNode
}

const BillContainer = styled.ul`
  border: 1px solid ${({ theme }) => theme.colors.placeholder};
  border-radius: 5px;

  & > :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.placeholder};
  }
`

const Bill: React.FC<BillProps> = ({
  storeName,
  categories,
  webPromo,
  children,
  additionnalTransactions,
  selected,
  setSelected,
}) => {
  const total = useMemo(() => {
    //somme des pieces selectionnées
    return categories && categories.length > 0
      ? Array.from(selected.values())
          .map(
            (selectedIdx, idx) =>
              categories[idx]?.pricings[selectedIdx].priceTTC
          )
          .reduce((previous, current) => previous + current, 0) +
          (webPromo ?? 0) +
          (additionnalTransactions?.reduce((accu, val) => accu + val, 0) ?? 0)
      : undefined
  }, [categories, webPromo, additionnalTransactions, selected])
  return children ? (
    <BillWrapper>
      <BillContainer>
        <BillItemChoice
          choices={categories}
          selected={selected}
          setSelected={setSelected}
          storeName={storeName}
        />
      </BillContainer>
      {children}
      <BillContainer>
        <BillItem strong label="Total" value={total} webPromo={webPromo} />
      </BillContainer>
    </BillWrapper>
  ) : (
    <BillContainer>
      <BillItemChoice
        choices={categories}
        selected={selected}
        setSelected={setSelected}
        storeName={storeName}
      />
      <BillItem strong label="Total" value={total} webPromo={webPromo} />
    </BillContainer>
  )
}

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    padding-left: 14px;
  }
`

const Description = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
`

const ButtonWithDescriptionContainer = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  & > *[class^='RepairSummary__Description'] {
    max-width: 245px;
  }
`

interface ButtonWithDescriptionProps {
  button: React.ReactNode
  description: React.ReactNode
}

const ButtonWithDescription: React.FC<ButtonWithDescriptionProps> = ({
  button,
  description,
}) => (
  <ButtonWithDescriptionContainer>
    {button}
    <Description>{description}</Description>
  </ButtonWithDescriptionContainer>
)

const RepairContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  margin-left: 0px;

  & > :last-child  {
    align-self: flex-end;
  }
  & > :first-child  {
    align-self: stretch;
  }
`

const RepairLabelContainer = styled.div`
  display: flex;

  ul {
    list-style: none;
  }

  & > :first-child {
    flex: 1;
  }

  align-items: flex-start;
`

const RepairLabel = styled.strong`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 13px;
    line-height: 16px;
  }
`

interface RepairProps {
  label: React.ReactNode[]
  value?: number
}

const Repair: React.FC<RepairProps> = ({ label, value }) => (
  <RepairContainer>
    <RepairLabelContainer>
      <ul>
        {label.map((l, selectedIdx) => (
          <li key={selectedIdx}>
            <RepairLabel>{l}</RepairLabel>
          </li>
        ))}
      </ul>

      <StaticImage
        src="../images/RepairIndicator.svg"
        alt=""
        placeholder="blurred"
        quality={100}
      />
    </RepairLabelContainer>
    {value === undefined ? <LoadingIcon /> : <Price value={value} />}
  </RepairContainer>
)

const BlockquoteDiv = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex: 1;
`

const BlockquoteLabel = styled.blockquote`
  font-family: 'Roboto', cursive;
  background: #fffdf5;
  color: #3f3f5a;

  &:before {
    content: '“';
    left: -5rem;
    top: -2rem;
  }

  &:after {
    content: '”';
    right: -5rem;
    bottom: 1rem;
  }
`
interface BlockquoteProps {
  label: string
}

const Blockquote: React.FC<BlockquoteProps> = ({ label }) => (
  <BlockquoteDiv>
    <BlockquoteLabel>{label}</BlockquoteLabel>
  </BlockquoteDiv>
)

const Address = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  text-align: center;
  padding: 2rem 2rem 3rem 2rem;
`

export default Object.assign(RepairSummary, {
  Card,
  ShopAvatar,
  RecoverAvatar,
  DynamicAvatar,
  PhoneAvatar,
  Summary,
  Buttons,
  Button,
  ButtonWithDescription,
  Bill,
  Repair,
  Address,
  ErrorMessage,
  Blockquote,
})
